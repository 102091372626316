import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { HeroSection, LargeSection } from "../components/containers"
import { H1, H2, H4, P } from "../components/typography"
import { FlDivider, InsDivider } from "../components/dividers"
import { ListItems } from "../components/lists/listItems"

const CapabilitiesPage = () => {
  const strategyList = [
    `Communication`,
    `Brand Architecture & Portfolio`,
    `Brand Positioning`,
    `Digital Transformation`,
    `Search, Data & Analytics`,
    `Integrated Campaigns`,
    `Community Engagement & Loyalty`,
    `Future Visioning`,
  ]

  const designList = [
    `Service Design`,
    `Experience & Interaction Design`,
    `Verbal & Visual Identity`,
    `Design System`,
    `Content & Messaging`,
    `Rapid Prototyping`,
  ]

  const technologyList = [
    `Web Application`,
    `Native Application`,
    `Commerce`,
    `Voice`,
    `Internet of Things`,
    `AR/VR`,
  ]

  return (
    <Layout>
      <SEO
        title="Capabilities"
        description="Learn how we use strategy, design, and technology to deliver value to customers and lead organizations to future innovation. Concrezan is an independent brand experience and service design consultancy."
      />
      <HeroSection>
        <H4>What We Do</H4>
        <H1>Strategy, design, and technology.</H1>
        <P>
          Capabilities for the successful delivery of brand experience, service
          design, and future innovation.
        </P>
      </HeroSection>
      <FlDivider />
      <LargeSection>
        <H2>Strategy</H2>
        <P>
          You can build anything you want, but without an appropriate strategy
          it will be hard to deliver.
        </P>
        <P>
          Our work begins with us getting to know you, your internal structures,
          and customers. Together, we head out into the field in search of a
          shared mission. Observing people’s behaviors and interactions as we
          help them articulate needs they may not even know exist. We return
          people to the center of your brand’s story, shifting your focus from a
          “what can we sell” to a “how can we support them?”
        </P>
        <P>
          Once we figured out your direction, we draw up a blueprint and we call
          it strategy. A blueprint that builds trust and delivers meaningful
          brand and service experiences to people.
        </P>
        <ListItems lists={strategyList} />
      </LargeSection>
      <InsDivider />
      <LargeSection>
        <H2>Design</H2>
        <P>
          A story humanely narrated will deliver a powerful emotional message.
          Design goes beyond physical and digital spaces, it’s the emotional
          aspect of us humans.
        </P>
        <P>
          In practice, we engage everyone with a stake in the design from the
          start. Placing emphasis on your customer journey in relation to your
          brand and service operations. We co-create the content, user
          interfaces, and way findings systems. Our work is to design meaningful
          experiences for the individuals and deliver organizational benefits
          all at the same time.
        </P>
        <ListItems lists={designList} />
      </LargeSection>
      <InsDivider />
      <LargeSection>
        <H2>Technology</H2>
        <P>
          The great thing modern technology does is change how we work and
          interact with the world. It presents creative ways for your brand to
          serve and build meaningful relationships with people.
        </P>
        <P>
          Whether it’s a small app for your internal use, a radical approach to
          physical spaces, or a platform for millions in your community, we can
          help you build and personalize your services at scale. Delivering
          valuable experiences to people today that leads to future innovation.
        </P>
        <ListItems lists={technologyList} />
      </LargeSection>
    </Layout>
  )
}

export default CapabilitiesPage
