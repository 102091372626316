import React from "react"
import styled from "styled-components"

import { colors, fonts, media } from "../../utils/tokens"

const Container = styled.ul`
  margin: 4rem 0 0;
  padding: 0;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  list-style: none;

  @media ${media.lg} {
    max-height: 8rem;
  }

  @media ${media.xl} {
    margin: 6rem 0 0;
    max-height: 9rem;
  }
`

const Item = styled.li`
  margin: 0 0 1rem;
  font-size: ${fonts.metaSM};
  font-weight: ${fonts.reg};
  line-height: ${fonts.metaLineHeight};
  letter-spacing: ${fonts.metaSpace};
  color: ${colors.limestone};

  &:last-of-type {
    margin: 0;
  }

  &::before {
    content: "—";
    margin: 0 .5rem 0 0;
  }

  @media ${media.lg} {
    &:nth-of-type(4) {
      margin: 0;
    }
  }

  @media ${media.xl} {
    font-size: ${fonts.metaMD};
    margin: 0 0 1.5rem;

    &::before {
      margin: 0 1rem 0 0;
    }
  }
`

export const ListItems = ({ lists }) => (
  <Container>
    {lists.map((list, index) => (
      <Item key={index}>{list}</Item>
    ))}
  </Container>
)
